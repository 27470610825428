import * as React from "react"
import Template from "../components/template"

const AboutPage = () => {
  return (
    <Template>
      <main className="max-w-screen-lg mx-auto">Chiamateci avvavvavva!!!</main>
    </Template>
  )
}

export default AboutPage
